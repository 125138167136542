import {Animator, MoveOut} from "react-scroll-motion"

export default function HeroComponent() {
    return (
        <div className="flex justify-center align-middle h-full bg-contain bg-center bg-no-repeat" style={{
            backgroundImage: "url(hero-image.webp)"
        }}>
                  <span className="m-auto text-6xl md:text-9xl text-neutral-500">
                    <Animator animation={MoveOut(-1000, 0)}><h1>Sanjin</h1></Animator>
                    <Animator animation={MoveOut(1000, 0)}><h1 className="pl-32">Kurelić</h1></Animator>
                  </span>
        </div>
    )
}