import {SlideItem} from "../model/SlideItem"

export default function useGetHomeItems() {
    return {
        getConference(): SlideItem {
            return {
                title: "home.conference.title",
                descriptions: ["home.conference.description"],
                images: ["/experience/javacro.webp"],
                actions: [],
            }
        },
        getOtherProjects(): SlideItem {
            return {
                title: "home.other.title",
                descriptions: ["home.other.description"],
                images: [
                    "/experience/other/cbc.webp",
                    "/experience/other/meeting.webp",
                    "/experience/other/pos.webp",
                    "/experience/other/sights.webp",
                ],
                actions: [],
            }
        },
        getQuote(): SlideItem {
            return {
                title: "home.quote.title",
                descriptions: [],
                images: [],
                actions: [],
            }
        },
        getContactInformation(): SlideItem {
            return {
                title: "home.contact.title",
                descriptions: [],
                images: [
                    "/experience/contact.webp"
                ],
                actions: [
                    {
                        text: "kurelic@sanjin.eu",
                        path: "mailto:kurelic@sanjin.eu"
                    },
                    {
                        text: "+385 98 99 11 949",
                        path: "tel:+385989911949"
                    },
                    {
                        text: "LinkedIn",
                        path: "https://www.linkedin.com/in/kureli%C4%87-sanjin/"
                    },
                    {
                        text: "GitHub",
                        path: "https://github.com/SanjinKurelic"
                    },
                ],
            }
        },
        getTechnology(): SlideItem {
            return {
                title: "home.technology.title",
                descriptions: ["home.technology.description"],
                images: [],
                actions: [
                    {
                        text: "common.action.explore",
                        path: "/technology"
                    },
                ],
            }
        },
        getExperiences(experiences: string[]): SlideItem[] {
            return experiences.map((experience) => {
                const translation = "experience." + experience
                const path = translation.replace(".", "/")

                return {
                    title: `${translation}.title`,
                    descriptions: [`${translation}.description.short`],
                    images: [`${path}.webp`],
                    actions: [
                        {
                            text: "common.action.explore",
                            path: `/${path}`
                        },
                    ]
                }
            })
        }
    }
}