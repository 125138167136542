import {Context, createContext} from "react"

export interface ScrollContextType {
    position: number
    setPosition: (position: number) => void
}

const defaultScrollContext: ScrollContextType = {
    position: 0,
    setPosition: () => {},
}

export const PositionContext: Context<ScrollContextType> = createContext(defaultScrollContext)