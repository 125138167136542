import {Animator, batch, Sticky} from "react-scroll-motion"
import {SlideItem} from "../model/SlideItem"
import ButtonComponent from "../../../components/ButtonComponent"
import {useTranslation} from "react-i18next"
import useAnimation from "../util/useAnimation"

export interface SlideItemComponentProps {
    item: SlideItem
}

export default function BasicSlideComponent({item}: SlideItemComponentProps) {
    const {t} = useTranslation()
    const {FastFade, LateVisibility} = useAnimation()

    return (
        <Animator animation={batch(Sticky(), FastFade())}>
            <article className="min-w-[300px] md:min-w-[700px] max-w-[1000px]">
                <header className="flex items-center">
                    {item.images.length > 0 &&
                        <img className="md:mb-1 mr-3 md:ml-5 filter invert dark:filter-none" src={item.images[0]} alt="" width="48px" height="48px"/>}
                    <h3 className="text-3xl md:text-4xl 2xl:text-6xl py-3">{t(item.title)}</h3>
                </header>
                <section className="text-lg md:text-xl md:mt-8">
                    {item.descriptions.map((description: string, index: number) =>
                        <p key={index} className="mt-2 md:pl-6 dark:font-extralight font-light">{t(description)}</p>
                    )}
                    {item.actions.map((action, index) =>
                        <Animator key={index} animation={LateVisibility()}>
                            <ButtonComponent text={action.text} path={action.path}/>
                        </Animator>
                    )}
                </section>
            </article>
        </Animator>
    )
}