export const common = {
    action: {
        explore: "Read more",
        back: "Return",
        home: "Go to main page",
    },
    title: {
        home: "Home",
        blog: "Blog",
    },
    error: {
        missing: "The requested page was not found."
    }
}