import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

export default function NotFound() {
    const {t} = useTranslation()

    return (
        <article className="flex align-middle justify-center py-44 px-4 flex-col">
            <p className="text-2xl text-center">{t('common.error.missing')}</p>
            <div className="text-center mt-8">
                <NavLink
                    className="dark:bg-neutral-400 bg-neutral-300 text-black py-2 px-5 m-4 rounded-full font-bold hover:opacity-80"
                    to="/">{t(`common.action.home`)}</NavLink>
            </div>
        </article>
    )
}