import NavigationComponent from "./component/NavigationComponent"
import ThemeComponent from "./component/ThemeComponent"

export default function Header() {
    return (
        <header className="flex z-10 backdrop-blur justify-between sticky top-0">
            <NavigationComponent/>
            <ThemeComponent />
        </header>
    )
}