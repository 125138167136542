import {common} from "./translations/common_en"
import {home} from "./translations/home_en"
import {experience} from "./translations/experience_en"
import {technology} from "./translations/technology_en"
import {blog} from "./translations/blog/blog_common_en"

export const en = {
    translation: {
        home,
        experience,
        common,
        technology,
        blog,
    }
}