export const technology = {
    title: "Technology",
    default: "Please select technology group on left side",
    category: {
        ai: "AI",
        backend: "Backend Developer",
        data: "Data Engineer",
        desktop: "Desktop Developer",
        devops: "DevOps",
        frontend: "Frontend Developer",
        language: "Programming Languages",
        mobile: "Mobile Developer",
        monitoring: "Monitoring and Testing Engineer",
        other: "Other",
    }
}