import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import {en} from "./locales/en"
import i18next from "i18next"
import {de} from "./locales/de"
import {it} from "./locales/it"

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en,
            //de,
            //it,
        },
        fallbackLng: "en",
    })
    .catch((error) => console.error("Error while loading translation", error))