import React, {useState} from "react"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import NotFound from "./pages/NotFound"
import Blog from "./pages/blog/Blog"
import Experience from "./pages/experience/Experience"
import Technology from "./pages/technology/Technology"
import Header from "./pages/header/Header"
import Home from "./pages/home/Home"
import {PositionContext} from "./context/PositionContext"

export default function App() {
    const [position, setPosition] = useState<number>(0)

    return (
        <PositionContext.Provider value={{position, setPosition}}>
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path="/blog" element={<Blog/>}/>
                    <Route path="/experience/:id" element={<Experience/>}/>
                    <Route path="/technology" element={<Technology/>}/>
                    <Route path="/" element={<Home/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </PositionContext.Provider>
    )
}
