export const home = {
    conference: {
        title: "Speaker",
        description: "Delivered a series of lectures and presentations at various conferences, covering topics ranging from system alerting and monitoring to the development of Selenium integration tests and Wiremock mocks utilizing AI.",
    },
    contact: {
        title: "Let's keep in touch",
    },
    technology: {
        title: "Technology",
        description: "Throughout my long-standing career, I have gained extensive experience in various technologies. Starting my career in 2011, I've primarily focused on mobile software development. Over the years, I've acquired comprehensive knowledge in developing web and desktop applications, emphasizing their interoperability and actively contributing to CI/CD processes.",
    },
    other: {
        title: "And many others",
        description: "Designed and implemented over 80 mobile, desktop, and web applications that assist small and large businesses in accelerating their business activities, encompassing diverse sectors from restaurants, hair salons, and fitness centers to hotels and camps."
    },
    quote: {
        title: "Let's shape your business to meet demands of digital age."
    },
}