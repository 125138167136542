import {NavLink, useLocation} from "react-router-dom"
import {useCallback, useEffect, useState} from "react"

export default function NavigationComponent() {
    const location = useLocation()

    const getTitle = useCallback((): string => {
        const isHome = location.pathname === "/"

        return (isHome && (window.scrollY + 1) < window.innerHeight) ? "SK" : "SANJIN KURELIĆ"
    }, [location])

    const [title, setTitle] = useState(getTitle())

    useEffect(() => {
        const handleScroll = () => {
            setTitle(getTitle)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [getTitle])

    return (
        <nav className="inline-block p-2">
            <NavLink to="/" className="inline-block m-1.5 mx-2">{title}</NavLink>
        </nav>
    )
}