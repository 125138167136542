export interface Technology {
    category: string,
    technologies: string[],
}

export default function useGetTechnology(): Technology[] {
    const store = technologiesStore()

    return [
        {
            category: "language",
            technologies: store.getProgrammingLanguages()
        },
        {
            category: "ai",
            technologies: store.getAiTechnologies()
        },
        {
            category: "backend",
            technologies: store.getBackendTechnology()
        },
        {
            category: "data",
            technologies: store.getDataTechnologies().concat(store.getDalTechnologies())
        },
        {
            category: "desktop",
            technologies: store.getDesktopTechnologies()
        },
        {
            category: "devops",
            technologies: store.getDevOpsTechnologies()
        },
        {
            category: "frontend",
            technologies: store.getFrontendTechnologies()
        },
        {
            category: "mobile",
            technologies: store.getMobileTechnologies()
        },
        {
            category: "monitoring",
            technologies: store.getMonitoringTechnologies().concat(store.getTestingTechnologies())
        },
        {
            category: "other",
            technologies: store.getOtherTechnologies()
        }
    ]
}

function technologiesStore() {
    return {
        getBackendTechnology(): string[] {
            return [
                "Spring MVC", "Spring Boot", "Symfony", "Laravel", "ASP .NET Core",
                "ASP .NET", "WebForms", "Jackson", "GSON", "SpringDoc", "Swagger", "OpenAPI",
                "Nimbus JOSE JWT", "Shedlock", "Resilience4j", "Feign",
                "Apache HTTP Client", "REST Assured", "Redis", "Redisson",
                "Jedis", "CircuitBreaker", "JavaEE/Servlet", "Django", "FLASK",
                "Express", "Node.JS", "RestTemplate", "TinyRadius"
            ]
        },
        getFrontendTechnologies(): string[] {
            return [
                "React", "RTK", "Axios", "Formik", "react-forms",
                "jQuery", "ExtJS", "Svelte", "SASS/SCSS",
                "Tailwind", "Bootstrap", "Razor", "Blazor", "JSP",
                "JSF", "IceFaces", "Dojo", "Gulp", "Modernizr", "Uglify",
                "single-spa", "micro frontend", "JSTL", "Webpack",
                "i18next", "yup", "redux", "react-query", "moment",
                "storeon", "zustand", "signals", "eslint", "postcss",
                "NPM", "PNPM", "Yarn", "PropTypes", "react-router",
                "Promises", "WebRTC",
            ]
        },
        getDesktopTechnologies(): string[] {
            return [
                "Qt", "QML", "Java Swing", "JavaFX", "WPF", "WinForms",
                "Unix", "GTK",
            ]
        },
        getMobileTechnologies(): string[] {
            return [
                "Android 2+", "Symbian", "Kotlin", "QML", "Retrofit",
                "NewtonSoft JSON", "WP", "Picasso", "Fragments",
                "DataBiding", "RoomDAO", "RxAndroid", "RecyclerView",
                "MVVM", "NavigationDrawer", "ViewHolder", "Adapters",
                "ViewPager", "Contractors", "SOC", "SRP", "ButterKnife",
                "MutableLiveData", "ViewModelProviders", "RxJava", "ReactiveX",
                "XNA", "Maemo/MeeGO",
            ]
        },
        getDevOpsTechnologies(): string[] {
            return [
                "Jenkins", "ArgoCD", "AWS", "OpenShift", "Docker", "docker-compose",
                "Kubernetes", "CD/CI", "GitHub actions", "GitLab actions", "BitBucket",
                "Keycloak", "OAuth", "Zipkin", "JBoss", "WebSphere", "IBM IHS",
                "Cisco ISE", "Entrust Identity Guard",
            ]
        },
        getDalTechnologies(): string[] {
            return [
                "Enterprise DAAB", "EntityFramework", "Hibernate", "JPA",
                "Eclipse Link", "ADO .NET", "SQLHelper", "SQLAlchemy",
                "ActiveMQ", "IBM MQ", "Kafka", "JPA modelgen", "Groovy GORM",
                "JMS", "Atomikos", "MuleESB",
            ]
        },
        getDataTechnologies(): string[] {
            return [
                "Microsoft SQL Server", "PostgreSQL", "MySQL", "H2",
                "Oracle SQL", "TSQL", "SQLite", "Data warehouse",
                "OLAP cube", "ETL", "Microsoft SSIS", "PowerBI", "Data Mining",
                "Liquibase", "Business analytics", "Business intelligence",
                "Microsoft Access",
            ]
        },
        getMonitoringTechnologies(): string[] {
            return [
                "Grafana", "Prometheus", "Loki", "Kibana", "ELK - Elastic",
                "Dynatrace", "Dependency Track", "OWASP", "Slf4j", "Logbook",
                "Logstash", "Micrometer", "Spring Actuator", "Sentry", "Splunk"
            ]
        },
        getTestingTechnologies(): string[] {
            return [
                "JMeter", "Newman", "Postman", "Bruno", "Junit", "Jupiter",
                "Selenium", "Appium", "TestNG", "Spock", "React Testing Library",
                "Jest", "WireMock", "Mockito", "Awaitility", "TestContainers",
                "Instancio", "AssertJ", "JaCoCo", "enzyme", "Selenium Fluent",
                "unit testing", "contract testing", "integration testing", "ui testing",
                "mobile testing", "automatic testing", "performance testing",
            ]
        },
        getProgrammingLanguages(): string[] {
            return [
                "Java", "C#", "C++", "PHP", "Python", "JavaScript", "Bash", "C",
                "TypeScript", "Groovy", "Rust", "Perl", "Visual Basic"
            ]
        },
        getAiTechnologies(): string[] {
            return [
                "pytesseract", "opencv-python-headless", "numpy", "imutils",
                "pandas", "missing values", "information value", "ChatGPT API",
                "Gemini", "Copilot", "weight of evidence", "ALPR", "OpenCV",
                "CBR", "OCR",
            ]
        },
        getOtherTechnologies(): string[] {
            return [
                "Constretto", "MapStruct", "Lombok", "Handlebars",
                "Apache CXF", "JSch", "Feature Togglz", "AspectJ", "Spring AOP",
                "Joda Time", "Apache PDFBox", "Tabula (PDF)",
                "iText", "JasperReports", "Javax mail", "JAX-WS", "JAXB",
                "JFreeChart", "OpenPGP", "Apache POI", "Quartz", "Spring Scheduler",
                "JNDI", "RMI", "Socks5", "Proxy", "Socket", "Arduino",
                "Google maps API", "Facebook API", "PayPal API", "Booking API",
                "ANTLR", "metasploit", "w3af", "nmap"
            ]
        }
    }
}