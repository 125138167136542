interface TagComponentProps {
    tags: string[]
}

export default function TagComponent({tags}: TagComponentProps) {
    const className = "inline-block text-xs rounded back px-2 py-1 m-1 dark:bg-neutral-800 bg-neutral-200 tracking-wider"

    return (
        <ul>
            {tags.sort((a: string, b: string) => a.localeCompare(b)).map((tag) => <li key={tag} className={className}>{tag}</li>)}
        </ul>
    )
}