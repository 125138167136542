import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./i18n/i18n"
import App from "./App"
import mailgo from "mailgo"

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
)

mailgo({
    dark: true,
    showFooter: false,
    actions: {
        skype: false
    }
})

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
)
