import ExperienceComponent from "./component/ExperienceComponent"
import useGetExperience from "../../storage/useGetExperience"
import {useParams} from "react-router-dom"
import NotFound from "../NotFound"

export default function Experience() {
    const {id} = useParams()
    // Check if parameter value is valid
    const experience = useGetExperience().find(experience => experience === id)

    return (
        <>
            {!experience && <NotFound/>}
            {experience && <ExperienceComponent experience={experience}/>}
        </>
    )
}