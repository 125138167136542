import {Animator, batch, Sticky} from "react-scroll-motion"
import {SlideItem} from "../model/SlideItem"
import {useTranslation} from "react-i18next"
import useAnimation from "../util/useAnimation"

export interface AtomicSlideComponentProps {
    item: SlideItem
}

export default function AtomicSlideComponent({item}: AtomicSlideComponentProps) {
    const {t} = useTranslation()
    const {FastFade} = useAnimation()


    return (
        <Animator className="text-center" animation={batch(Sticky(), FastFade())}>
            <span className="text-2xl md:text-3xl">{t(item.title)}</span>
        </Animator>
    )
}