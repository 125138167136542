import ThemeDarkIcon from "./ThemeDarkIcon"
import ThemeLightIcon from "./ThemeLightIcon"
import {useEffect, useState} from "react"

export default function ThemeComponent() {
    const [darkTheme, setDarkTheme] = useState(true)

    useEffect(() => {
        darkTheme ?
            document.documentElement.classList.add("dark") :
            document.documentElement.classList.remove("dark")
    }, [darkTheme])

    return (
        <>
            {!darkTheme && <ThemeDarkIcon onClick={() => setDarkTheme(true)}/>}
            {darkTheme && <ThemeLightIcon onClick={() => setDarkTheme(false)}/>}
        </>
    )
}