import {ScrollContainer, ScrollPage} from "react-scroll-motion"
import HeroComponent from "./components/HeroComponent"
import AtomicSlideComponent from "./components/AtomicSlideComponent"
import BasicSlideComponent from "./components/BasicSlideComponent"
import useGetHomeItems from "./storage/useGetHomeItems"
import GallerySlideComponent from "./components/GallerySlideComponent"
import useGetExperience from "../../storage/useGetExperience"
import {useContext, useEffect} from "react"
import {PositionContext} from "../../context/PositionContext"

export default function Home() {
    const items = useGetHomeItems()
    const experiences = useGetExperience()
    const {position, setPosition} = useContext(PositionContext)

    const handleScroll = () => {
        setPosition(window.scrollY)
    }

    useEffect(() => {
        // If position is already set (we are returning from dialog window), scroll to it
        setTimeout(() => {
            window.scrollTo(0, position)
        }, 0)

        // Start "recording" the scroll position
        window.addEventListener('scroll', handleScroll)

        // End "recording" the scroll position
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const pages = [
        <HeroComponent/>,
        <AtomicSlideComponent item={items.getQuote()}/>,
        ...items.getExperiences(experiences).map(experience => <BasicSlideComponent item={experience}/>),
        <GallerySlideComponent item={items.getOtherProjects()}/>,
        <GallerySlideComponent item={items.getConference()}/>,
        <BasicSlideComponent item={items.getTechnology()}/>,
        <GallerySlideComponent item={items.getContactInformation()}/>,
    ].map((page, index) => <ScrollPage key={index}>{page}</ScrollPage>)

    return (
        <ScrollContainer children={pages}/>
    )
}