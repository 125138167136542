interface ThemeDarkIconProps {
    onClick: () => void
}

export default function ThemeDarkIcon({onClick}: ThemeDarkIconProps) {
    return (
        <div className="p-2 cursor-pointer" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="24"
                 height="24"
                 viewBox="0 0 24 24"
                 fill="none"
                 stroke="currentColor"
                 strokeWidth="2"
                 strokeLinecap="round"
                 strokeLinejoin="round"
            >
                <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
            </svg>
        </div>
    )
}