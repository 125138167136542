import TagComponent from "../../components/TagComponent"
import {useTranslation} from "react-i18next"
import useGetTechnology from "./storage/useGetTechnology"
import OutlinedButtonComponent from "../../components/OutlinedButtonComponent"
import {useEffect, useState} from "react"

export default function Technology() {
    const {t} = useTranslation(undefined, {keyPrefix: "technology"})
    const technologies = useGetTechnology()

    const groups = technologies.map(technology => technology.category)
    const [currentGroup, setCurrentGroup] = useState(groups[0])
    const currentTechnologies = technologies.find(technology => technology.category === currentGroup)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <article
        className="min-w-[300px] md:min-w-[700px] max-w-[800px] m-auto">
        <h2 className="text-3xl border-b-2 p-2 border-neutral-800">{t('title')}</h2>
        <section className="m-5 grid-cols-3 grid">
            <ul className="flex-column text-sm font-medium md:me-4 mb-4 md:mb-0">
                {groups.map((group: string, index: number) =>
                    <li key={index} className={`inline-flex items-center p-2 cursor-pointer hover:font-bold w-full${group === currentGroup ? " font-bold" : " text-neutral-400"}`}
                        onClick={() => setCurrentGroup(group)}>{t(`category.${group}`)}</li>
                )}
            </ul>
            <div className="p-6 col-span-2">
                {currentTechnologies && <TagComponent tags={currentTechnologies.technologies}/>}
            </div>
        </section>
        <div>
            <OutlinedButtonComponent text="back" path="/"/>
        </div>
    </article>
}