import {useTranslation} from "react-i18next"

export default function Blog() {
    const {t} = useTranslation()

    return (
        <article
            className="min-w-[300px] md:min-w-[700px] max-w-[800px] m-auto">
            <h2 className="text-3xl border-b-2 p-2 border-neutral-800">{t('common.title.blog')}</h2>
            <section className="p-4 mt-4">
                <p>{t('blog.common.info')}</p>
            </section>
        </article>
    )
}