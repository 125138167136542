export const experience = {
    common: {
        description: "description",
        technology: "technology",
        role: "role",
    },
    lng: {
        title: "LNG Croatia",
        roles: "Software Developer,Solution Architect",
        description: {
            short: "Engineered and implemented a sophisticated system that seamlessly connects the LNG ship to the Croatian gas pipeline infrastructure, adhering to international standards. This strategic integration facilitates the introduction of external gas sources into the market, thereby enhancing accessibility and reliability in the gas supply chain.",
            business:
                "I've integrated the newly established Liquefied Natural Gas (LNG) floating terminal with the Croatian Gas Transmission System Operator network.\n" +
                "The system, which is of exceptional strategic importance, allows gas service providers in Croatia to lease gas that arrives via LNG carrier vessels.\n" +
                "I've facilitated integration using the European standard for data exchange prescribed by the European Network of Transmission System Operators for Gas (ENTSOG).",
            technical:
                "The integration was accomplished using the AS2/AS4 protocol with support for various levels of encryption and message integrity assurance.\n" +
                "To ensure system robustness, a series of retry mechanisms and communication validity checks were implemented.\n",
        },
        technologies: "SOAP,AS4,ebMS,XML Digital Signature,XML Encryption,ArcESB",
    },
    plinacro: {
        title: "Plinacro",
        roles: "Software Developer",
        description: {
            short: "Revamped a decade-old complex system onto cutting-edge technologies, significantly boosting operational efficiency, streamlining functionality, and delivering an enhanced user experience. Additionally, a series of contemporary security mechanisms were implemented to align the system with the most current security standards.",
            business:
                "I've played a key role in modernizing a decade-old system, transitioning it onto cutting-edge technologies.\n" +
                "This facilitated a significant boost in operational efficiency, streamlined functionality, and delivered an enhanced user experience.\n" +
                "Additionally, a series of contemporary security mechanisms were implemented to align the system with the most current security standards.",
            technical:
                "I've upgraded from the old WebSphere Java 6 application to a new JBoss server with the latest Java and Spring framework. With the exception of the framework, I've upgraded all utilized libraries such as Quartz and Apache POI which required meticulous testing due to numerous breaking changes.\n" +
                "I've transformed the old IceFaces portal into a new single-spa shell, enabling the use of modern technologies for a better end-user experience.\n" +
                "I've also developed integration with Cisco ISE, upgrading the overall system security and providing an extra layer of protection against known vectors of attack.",
        },
        technologies: "Spring Boot,single-spa,JSP,Cisco ISE,Quartz,Apache POI,ExtJS,TinyRadius,IBM IHS",
    },
    raiffeisen: {
        title: "Raiffeisen bank",
        roles: "Tech Lead,Team Lead,Software Developer",
        description: {
            short: "Led a revolutionary transformation in the loan acquisition process for enterprises, enabling a shift from traditional bank visits to a fully automated system, resulting in a substantial reduction of cash disbursement time from 7 days to a mere 20 minutes.",
            business:
                "Led a revolutionary transformation in the loan acquisition process for small and medium enterprises, enabling a shift from traditional bank visits to a fully automated system.\n" +
                "The process has undergone complete automation, resulting in a substantial reduction of cash disbursement time from 7 days to a mere 20 minutes. The process costs were decreased by 90%.\n" +
                "As one of the initial international agile projects, defining all business processes within given time frames and in an agile manner was quite challenging.\n",
            technical:
                "During the system development, cutting-edge technologies such as cloud, microservices, and standardized contract-first development were employed, necessitating the modification or replacement of existing legacy systems.\n" +
                "Given the possibility of requesting substantial loan amounts during disbursement, numerous security mechanisms, rules, performance checks, and vulnerability scans were implemented. I've played a crucial role in implementing production issue monitoring and alerting, facilitating prompt responses to any identified problems. Additionally, I've defined the architecture of Selenium test cases to ensure comprehensive testing coverage and robustness in the application.\n" +
                "I've contributed to the design and development of multiple microservices and their integration with external and existing systems using Kafka, IBM MQ, and REST/SOAP integrations.\n"
        },
        technologies: "AWS,Dynatrace,Prometheus - Grafana,JMeter,Kafka,OAuth,OpenAPI,Spring Boot,Selenium,MQ,CircuitBreaker",
    },
}