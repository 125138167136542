import {Animation, Animator, batch, Sticky} from "react-scroll-motion"
import {SlideItem} from "../model/SlideItem"
import {useTranslation} from "react-i18next"
import useAnimation from "../util/useAnimation"

interface GallerySlideComponentProps {
    item: SlideItem
}

export default function GallerySlideComponent({item}: GallerySlideComponentProps) {
    const {t} = useTranslation()
    const {Stack, FastFade, GroupIn, GroupOut, LateVisibility} = useAnimation()

    // hack for Contacts page
    const isContactPage = item.actions.length > 1
    const groupAnimation = (index: number): Animation => isContactPage ?
        batch(GroupIn(index), FastFade()) :
        batch(GroupIn(index), GroupOut(index), FastFade())

    return (
        <Animator animation={Sticky()}>
            <article className="md:flex min-w-[300px] md:min-w-[700px] max-w-[1000px] xl:min-w-[1200px] md:flex-row">
                <aside className="hidden md:block basis-1/3 relative">
                    {item.images.map((image, index) =>
                        <Animator key={index} className="absolute" animation={batch(Stack(index), FastFade())} style={{
                            marginTop: `${index * 40}px`
                        }}>
                            <img src={image} alt=""/>
                        </Animator>
                    )}
                </aside>
                <Animator animation={FastFade()} className="basis-2/3">
                    <section>
                        <header className="xl:mt-8">
                            <h3 className="text-3xl md:text-4xl 2xl:text-6xl p-3 2xl:p-6">{t(item.title)}</h3>
                        </header>
                        {item.descriptions.map((description: string, index: number) =>
                            <p key={index} className="text-lg md:text-xl mt-2 md:pl-8 dark:font-extralight font-light">
                                {t(description)}
                            </p>
                        )}
                        {item.actions.map((action, index) =>
                            <Animator animation={LateVisibility()} key={index}>
                                <a className={`hover:font-bold text-lg md:text-xl md:pl-8 font-light block underline underline-offset-8 mt-4`}
                                   href={action.path}>{action.text}</a>
                            </Animator>
                        )}
                    </section>
                </Animator>
                <aside className="md:hidden relative h-48 p-6">
                    {item.images.map((image, index) =>
                        <Animator key={index} className="absolute" animation={groupAnimation(index)} style={{
                            marginTop: `${index * 40}px`
                        }}>
                            <img src={image} alt=""/>
                        </Animator>
                    )}
                </aside>
            </article>
        </Animator>
    )
}