import {Animation} from "react-scroll-motion"

export default function useAnimation() {
    return {
        Stack(offset: number): Animation {
            return {
                in: {
                    style: {
                        transform: (value: number): string => `translate(0px, ${(1000 * (1 - value)) + (10000 * Math.pow((1 - value), 2) * offset)}px)`
                    },
                }
            }
        },
        GroupIn(offset: number): Animation {
            return {
                in: {
                    style: {
                        transform: (value: number): string => `translate(-${(1000 * (1 - value)) + (10000 * Math.pow((1 - value), 2) * offset)}px, 0px)`
                    }
                },
            }
        },
        GroupOut(offset: number): Animation {
            return {
                out: {
                    style: {
                        transform: (value: number): string => `translate(${(1000 * value) + (10000 * Math.pow(value, 2) * offset)}px, 0px)`
                    }
                },
            }
        },
        FastFade(): Animation {
            return {
                in: {
                    style: {
                        opacity: (value: number): number => {
                            if (value < 0.5) {
                                return 0
                            }
                            if (value > 0.8) {
                                return 1
                            }

                            return 10 / 3 * value - 5 / 3
                        }
                    }
                },
                out: {
                    style: {
                        opacity: (value: number): number => {
                            if (value > 0.5) {
                                return 0
                            }
                            if (value < 0.2) {
                                return 1
                            }

                            return -10 / 3 * value + 5 / 3
                        }
                    }
                }
            }
        },
        LateVisibility(): Animation {
            return {
                in: {
                    style: {
                        display: (value: number): string => value < 0.5 ? 'none' : 'block'
                    }
                },
                out: {
                    style: {
                        display: (value: number) => value > 0.5 ? 'none' : 'block'
                    }
                }
            }
        },
    }
}