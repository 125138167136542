import {useTranslation} from "react-i18next"
import {NavLink} from "react-router-dom"

interface OutlinedButtonComponentProps {
    text: string
    path: string
}

export default function OutlinedButtonComponent({text, path}: OutlinedButtonComponentProps) {
    const {t} = useTranslation()

    return <NavLink className="dark:bg-neutral-400 bg-neutral-300 text-black py-2 px-5 m-4 float-right rounded-full font-bold hover:opacity-80"
                    to={path}>{t(`common.action.${text}`)}</NavLink>
}