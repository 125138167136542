import TagComponent from "../../../components/TagComponent"
import {useTranslation} from "react-i18next"
import {useEffect} from "react"
import OutlinedButtonComponent from "../../../components/OutlinedButtonComponent"
import SmallDescriptionComponent from "../../../components/SmallDescriptionComponent"

interface ExperienceComponentProps {
    experience: string
}

export default function ExperienceComponent({experience}: ExperienceComponentProps) {
    const {t} = useTranslation(undefined, {keyPrefix: `experience.${experience}`})
    const {t: tc} = useTranslation(undefined, {keyPrefix: "experience.common"})

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const textStyle = "col-span-3 md:text-lg"

    return (
        <article
            className="min-w-[300px] md:min-w-[700px] max-w-[800px] m-auto">
            <header className={`flex justify-between border-b-2 p-2 border-neutral-800 `}>
                <h2 className="inline text-3xl">{t('title')}</h2>
            </header>
            <section className="grid grid-cols-4 gap-4 m-5 dark:text-neutral-300">
                <SmallDescriptionComponent text={tc("description")}/>
                <div className={textStyle}>
                    {t('description.business').split('\n').map((businessDescription: string, index: number) =>
                        <p key={index} className="mb-3">{businessDescription}</p>
                    )}
                </div>
                <span className="self-center"><SmallDescriptionComponent text={tc("role")}/></span>
                <div className={textStyle}>
                    <TagComponent tags={t('roles').split(',')}/>
                </div>
                <SmallDescriptionComponent text={tc("technology")}/>
                <div className={textStyle}>
                    {t('description.technical').split('\n').map((technicalDescription: string, index: number) =>
                        <p key={index} className="mb-3">{technicalDescription}</p>
                    )}
                </div>
                <div></div>
                <div className={textStyle}>
                    <TagComponent tags={t('technologies').split(',')}/>
                </div>
                <div></div>
                <div className={textStyle}>
                    <OutlinedButtonComponent text="back" path="/"/>
                </div>
            </section>
        </article>
    )
}