import {NavLink} from "react-router-dom"
import {useTranslation} from "react-i18next"

interface ButtonComponentProps {
    text: string
    path: string
}

export default function ButtonComponent({text, path}: ButtonComponentProps) {
    const {t} = useTranslation()

    return <NavLink className={`font-bold underline underline-offset-8 mt-8 md:mt-14 float-right hover:opacity-80`} to={path}>{t(text)}</NavLink>
}